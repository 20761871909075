.input_radio label, input{
    cursor: pointer;
    padding: 0rem;
    // margin: .5rem;
    transform: scale(2);
    margin-bottom: 1em;   
    
    }
    
    .input_radio [type="radio"] {
    
        accent-color: #3EA31B;
      
        
    }
    
     input[type='radio']:checked + label {
        accent-color: #3EA31B;
        font-weight: bold;
        
    }
    