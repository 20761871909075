
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');

.modal {
    // display: none; /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1120;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
     @media (min-width:821px){
        width:30%;
     }
    /* Black w/ opacity */
}

//   /* Modal Content */
.modal__content {
    position: relative;
    top: 18%;
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 90%;
    border: 2px solid green;
    // width: 307px;
    height: 310px;
    flex-shrink: 0;
    border-radius: 50.317px;
    border: 2.327px solid #5F4981;
    background: #FFF;
    box-shadow: 0px 0px 1.2px 0px #8E54E9;

    @media(min-width: 400px){
        width: 70%;
        height: 300px;
        border: 2px solid green;
    }

}

//   /* The Close Button */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}


.parent {

    // border: 2px solid green;
    // margin-top: -10%;
    position: relative;
    top:-20px;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;

    .text {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;

        .heading {
            color: #302955;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 14px;
            text-align: center;
            /* 70% */
            letter-spacing: -0.24px;
        }

        .subHeading {
            color: #4A3D90;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            text-align: center;

            /* 116.667% */
            letter-spacing: -0.24px;
        }
    }

    .images {
        margin-top: 20px;
        display: flex;
        // gap: 5px;
        position: relative;
        width: 100%;
        justify-content: center;
        align-items: center;
        // border:2px solid green; 
        // gap:10%;s
        // align-items: center;
        // border: 2px solid blue;

        .img1 {
            // position: absolute;
            width: 40vw;
        // border: 2px solid orange;
        display: flex;
        justify-content: center;
        align-items: center;
        // padding:0px 150px;


            .boy_t {
                position: relative;  
                top: -40%;
                right: 13%;
                // margin-right: 18%;
            }
        }

        .img2 { 
            // position: absolute;
        // border: 2px solid green;
        display: flex;

        justify-content: center;
        align-items: center;    

        width: 40vw;


            .girl_t {
                position: relative;
                top: -40%;
                right: 13%;
            }
        }
    }

    .btn {
        margin-top: 20px;

        .perfectBtn {
            width: 164px;

            height: 35.533px;
            flex-shrink: 0;
            border-radius: 9.204px;
            border: 0.78px solid rgba(0, 0, 0, 0.00);
            background: #FA5743;
            box-shadow: 0px 2.51px 2.594px 0px #302955;

            p {
                color: #FFF;
                font-family: "Luckiest Guy";
                font-size: 15.061px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }
        }
    }
}