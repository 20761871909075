.container {
    width: 100%;
    max-width: 500px;
    // min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // top: 0px;
    margin-top: 50px;
    position: relative;


    @media (min-width:821px){
        width:100%
    }

    .count__div {
        // border: 2px solid blue;
        // position: absolute;
        width: 65%;
        display: flex;
        justify-content: center;
        margin-top: 45%;
        position: relative;
        // border: 2px solid green;

    @media (min-width:821px){
        width:100%
    }

        .count__number {
            color: #302955;
            font : normal 900 56px/15px Roboto;
            letter-spacing: -0.273px;
            position: absolute;
            // left:45%;
            top:30px;
        }

    }

    .animation {
        position: absolute;

        // @media (min-width:360px){

            // border: 12px solid green;
            top: 30px;
            width: 100%;

            @media (min-width:821px){
                top:10px;
                width: 60%;

            }
            @media (min-width: 500px) and (min-height: 612px) {
                top: 10px;
                width: 80%;
            }

            @media (min-width:821px){
                width: 60%;
            }
        // }
        

       
    }
}

