@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');

.parent {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    top:0px;
    // border: 2px solid green;
    background-color: rgba(0, 0, 0, 0.8);
    // opacity: 0.5;
    z-index: 50;

    .main {

        width: 303px;
        min-height: 171px;
        background-image: url('../../../../assets/theme6/popup_bg.svg');
        // clip-path: path("M10.746 29.0694C12.4198 11.7868 27.5708 -1.00759 44.8896 0.236452L273.293 16.643C290.033 17.8455 303 31.7773 303 48.5608V139C303 156.673 288.673 171 271 171H32.2489C13.3568 171 -1.42322 154.719 0.397958 135.915L10.746 29.0694Z");
        // background-color: #000;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        position: relative;
        // box-shadow: 0px 0px 100vh 100vh rgba(0, 0, 0, 0.8);        // border: 2px solid yellow;

        .heading {
            text-align: center;
            font-family: "Luckiest Guy";
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            /* 100% */
            letter-spacing: 0.76px;

            background: linear-gradient(97deg, #1DB75A 8.08%, #267826 75.03%);
            -webkit-background-clip: text;
            /* For Safari */
            -webkit-text-fill-color: transparent;
            /* For Safari */
            background-clip: text;
            color: transparent;
            position: relative;
            top:15px;
        }
        div{
            width: 320px;
            height: 100px;
            overflow: hidden;
            overflow-wrap: break-word;
            padding:10px;
            // margin-top: 20px;


        .para {
           
            // overflow: auto;
            color: #267826;
            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            /* 128.571% */
            letter-spacing: -0.24px;
            // position: relative;
            padding:10px;
            overflow-wrap: break-word;
            // text-align: center;
            // width: 70%;

        }

    }


    }

    .btn {
        width: 133px;
        height: 40px;
        flex-shrink: 0;
        border-radius: 11px;
        border: 0.932px solid rgba(0, 0, 0, 0.00);
        background: #FA5743;
        box-shadow: 0px 3px 3.1px 0px #302955;
        position: absolute;
        bottom: -15px;
        color: #fff;
        display: flex;
        justify-content: space-around;
        align-items: center;

        p {
            color: #FFF;    
            font-family: "Luckiest Guy";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 8px;
        }
    }


}