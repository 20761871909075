
.main_div{
    background:rgba(85, 61, 129, 1) ;
    // background: linear-gradient(180deg, rgba(142, 84, 233, 0.08) 0%, rgba(142, 84, 233, 0.00) 100%), linear-gradient(184deg, rgba(71, 118, 230, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
    min-height: 100vh;
    position: absolute;
    top:0;
    overflow: hidden;
    margin-top: 100px;
    border-top-left-radius: 30px; /* Set border radius for top-left corner */
  border-top-right-radius: 30px;

}



.trending__heading p{
    
    display: block;
    margin-top: 10px;
    margin-left: 30px;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0.44px;

  }



  .mission_sec {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    flex-wrap: nowrap;
    // overflow-x: hidden;
    white-space: nowrap;
    background: transparent;
    // height: 100vh;
    // margin: 10px 12px 10px 15px;
    border-radius: 10px;
    width: 100vw;
    // border:2px solid green; 
    padding-bottom: 30px;
    // width: 95%;
    //  gap: 20px;
    // border: 4px solid red;
    @media (min-width: 821px) {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // box-sizing: border-box;
      width: 30vw;
      // padding:5vw 5vw 5vw 5vw;
    }
    
    


    .mission {
      border: 2px solid gold;
      // min-width: auto;
      height: 170px;
      background: #fff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 15px;

     

      .first_section {
        display: flex;
        flex-direction: row;
        border: 2px solid red;
        width: 30%;
        border: 2px solid green;

        .mission_img {
          background-color: rgba(249, 207, 207, 0.2);
          border-radius: 50%;
          min-width: 61px;
        }

      }

      .second_section {

        width: 70%;
        border: 2px solid blue;

      }


    }

  }


  .continer {
    /* height: 100vh; */
    /* margin-top: 5%;
      // margin-bottom: 50%;
      margin-left: 5%;
      margin-right: 5%; */
      position: relative;
    //   overflow: hidden;
    margin: 5% 5%;
    margin-bottom: 10px;
    // background-color: white;
    // border-radius: .5rem;
    // border: 2px solid #553D81;
    padding: 2.5%;
    // overflow-x: hidden;
    height: 138px;
    width: 90vw;
    border-radius: 18px;
    background-image: linear-gradient(to left, #5F4981 100%, #76727C 100%, #8E54E9 17%);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
    border:2px solid rgba(255,255,255,0.17);

    @media (min-width: 821px) {
      width: 26vw;
    }
    

    // border: 2px solid;
  
  
  }
  
  
  .container1 {
    /* height: 50%; */
    display: flex;
    gap: 0.5rem;
    padding: 1%;
    /* overflow: hidden; */
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    // background: rgba(142, 84, 233, 0.17)
  }
  
  .image_div {
    // width: 25%;
    height: 100px;
    width: 100px;
    // margin-left: -12px;
    // border-radius: 100%;
    margin-left: 0px;
    overflow: hidden;
    //     width: 107px;
    // height: 96px;
    flex-shrink: 0;
    border-radius: 16px;
    background:rgba(142, 84, 233, 0.17);
    clip-path: path('M4.51684 26.1476C5.11469 17.6419 11.6622 10.7557 20.1269 9.73004L98.0651 0.286152C108.664 -0.998129 118 7.27495 118 17.9513V86.8426C118 96.6701 110.033 104.637 100.206 104.637H18.089C7.77019 104.637 -0.38508 95.8884 0.338423 85.5949L4.51684 26.1476Z');

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  
    @media screen and (max-width: 400px) {
      width: 80px;
      height: 80px;
    }
  
    @media screen and (max-width: 320px) {
      width: 70px;
      height: 70px;
    }

    @media screen and (max-width: 290px) {
      width: 50px;
      height: 50px;
    }
  }
  
  .item_container {
    width: 63%;
    /* height: 120%; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-left: 10px;
    /* gap: 10%; */
  }
  
  .fast_food_detail_container {
    display: flex;
    gap: 3px;
    width: 180px;
    height: 14px;
    justify-content: space-between;
    align-items: center;
    color: #fff;

    // border:2px solid red;
  }
  
  .food_detils {
    // width: 43px;
    height: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 13px;
  }
  
  .food_image {
    height: 100%;
    width: 100%;
    object-fit: contain;
    aspect-ratio: 1;
  
    @media screen and (max-width: 400px) {
  
  
      border: 1px solid #F8E1E3;
    }
  
    @media screen and (max-width: 300px) {
  
  
      border: 1px solid #F8E1E3;
    }
  }
  
  .button_9 {
    
   
    -webkit-appearance: button;
    appearance: button;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    // background-color: #6a38ef;
    border-radius: 6px;
    border-width: 0;
    // box-shadow: inset 0 0 0 1px rgba(50, 50, 93, .1), 0 2px 5px 0 rgba(50, 50, 93, .1), 0 1px 1px 0 rgba(0, 0, 0, .07);
    box-sizing: border-box;
    // color: #553D81;
    // cursor: pointer;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    height: 35px;
    line-height: 1.15;
    outline: none;
    // overflow: hidden;
    padding: 0 100px 0 10px;
    position: absolute;
    right:0;
    bottom:-15px;
    text-align: center;
    text-transform: none;
    // touch-action: manipulation;
   
    // transition: all .2s, box-shadow .08s ease-in;
    user-select: none;
    -webkit-user-select: none;
    width: 38%;
    border-radius: 10px;
    background: #FFBA4D;
    border: 2px solid #FFF6CE;
    // transform: translate(-50%, -50%);
    font-weight: 500;
    z-index: 12;
    color: #553D81;
    // border:2px solid green;

    .btn_img{
        position: absolute;
        right: -9px;
        bottom:-15px;
    // border:2px solid red;

    }
  }
  
  .button_9:disabled {
    cursor: default;
  }
  
  .button_9:focus {
    box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 6px 15px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
  }
  
  .Cash_bonanza_div {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 19px;
    /* UI Colors/Text Primary Color */
    color: #fff;
    font-family: Roboto;
    letter-spacing: 0.32px;
  
  }
  
  .Fast_food_div {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    margin: 5px 0;
    color: #fff;
  }
  
  .offer_div {
    position: absolute;
    z-index: 1;
    margin-left: 15%;
    /* right: .rem; */
  }


  .view_more {
    // background: linear-gradient(126deg, rgba(71, 118, 230, 0.76) 22.82%, rgba(142, 84, 233, 0.76) 88.59%);
    // background-clip: text;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    text-decoration-line: underline;
    color:#fff;
    // text-decoration-color: rgba(142, 84, 233, 0.77);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    margin-top: 25px;
  
  
  
  
  
  }


  .banner_sub_heading {
    display: block;
    margin-top: 30px;
    margin-left: 30px;
    // border:2px solid green;
  }

  .banner_sub_heading p {

      color: #FFF;

      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

  }

  .starting_text {
   
    /* 22px */
    letter-spacing: 0.44px;
    margin-left: 30px;
    margin-top: 10px;
    color: #FFF;

font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: normal;
  }