.theme__banner p {
    display: block;
    margin-top: 10px;
    margin-left: 24px;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 22px */
    letter-spacing: 0.44px;
  }

  .categories_sec {
    background-color: #FFF8F8;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: scroll;
    white-space: nowrap;
    background: transparent;
    height: 200px;
    // border:2px solid red;
    width: auto;

    // width:340px;

    margin: -30px 0px 0px 15px;
    border-radius: 10px;
    padding: 0px 25px 0px 15px;
    gap: 18px;

    .categorie {
      min-width: 113px;
      height: 116px;
      background: #fff;
      // border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 15px;
      border-radius: 80% 80% 20% 20%;
    //   background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(142, 84, 233, 0.17) 69.28%);
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
      padding-bottom: 30px;
      margin-bottom: -50px;


      .category_button {
        display: none;
      }

      .categorie_img {

        background-color: inherit;
        width: 96px;
        height: 96px;
        overflow: hidden;
        // border:2px solid black;
        border-radius: 0%;



      }

      .categorie_img img {
        display: flex;
        width: 84px;
        height: 90px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 0;
      }

      .categorie_p {
        color:#5D457E;
        font-size: 15px !important;
        font-weight: 600 !important;
        letter-spacing: 0.32px !important;
      }


    }

  }

  .categories_sec::-webkit-scrollbar {
    display: block;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .categories_sec {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .bottom_text {
    margin: 20px 15px 10px 15px;
    font-size: 2.3rem;
    font-family: 'Gantari', sans-serif;
    text-align: center;
    color: #4776E6;

    .color_text {
      color: #8E54E9;
    }
  }

  .jingle_text {
    // width: 265px;
    margin: 10px 15px 50px 15px;
    font-size: 16px;
    font-family: 'Gantari', sans-serif;
    text-align: center;
    color: #9CAEB2;
    line-height: 108%;
  }