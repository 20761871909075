.container {
    width: 100vw; /* Adjust width as needed */
    // min-height: 100vh; /* Adjust height as needed */
    position: relative; /* Ensure proper positioning for overlay */
    // border: 20px solid blue;


    // background-color: #FFC05C;

    @media (min-width:821px){
      width: 30vw;
    }


    @media (min-height:600px){  
      // height: 135vh;
      // border: 2px solid green;
    }

    
  }
  
  .container::before {
    content: ""; /* Create a pseudo-element */
    position: relative; /* Position the pseudo-element */
    top: 0px;
    left: 0;
    width: 100%; /* Cover the entire container */
    min-height: 136%;
    background-image: url('../../../../assets/theme6/bgart.png'); /* Specify the URL of your transparent image */
    background-color: #FFC05C; /* Specify the color overlay (here, it's red with 50% opacity) */
    opacity: 1; /* Adjust the opacity of the image */
    display: none;

 
    
  }
  