.image_div {
    width: 100%; /* Adjust width as needed */
    height: 100%; /* Adjust height as needed */
    // width: 281px;
//    height: 120px;    
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    

  }

  .qns_image {
    width: 100%;
// height: 100%;
max-height: 150px;
  object-fit: contain;
  border-radius: 30px;
  background-color: white;

//   @media (min-width:460px){
//     height: 25vh;
  
// }

// @media (min-width:510px){
//   height: 30vh;
    

    
// }

// @media (min-width:560px){
//   height: 35vh;

    

    
// }

// @media (min-width:600px){
//   height: 40vh;
   

    
// }


  }