.check_boxes label {
    cursor: pointer;
    display: flex;
   
    
  }

  .check_boxes input[type='checkbox'] {
    cursor: pointer;
    opacity: 0;
    position:absolute;
  }
.check_boxes label::before {
content: '';
width: 1em;
height: 1em;
border-radius: .15em ;
margin-right: .5em;
border: .05em solid black ;
background-color:white;
}

.check_boxes label:hover::before ,
.check_boxes input[type='checkbox']:hover + label::before{
background-color:white ;

}

.check_boxes input[type='checkbox']:checked + label::before{
content: '\002714';
background-color:#3EA31B ;
display: flex;
justify-content: center;
align-items: center;
color:white;  
font-weight: bold;


}


input[type="checkbox"]:checked + label{
font-weight: bold;
}
