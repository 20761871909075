@import url('https://fonts.googleapis.com/css2?family=Gantari:ital,wght@0,600;0,700;0,800;0,900;1,900&family=Inter&family=Poppins:wght@700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;900&family=Roboto:wght@400;500&display=swap');


html {
  scroll-behavior: smooth;
  background-image: url('./assets/bg/Quiz_wall\ bg_1.png');
  background-size: contain;
  background-attachment: fixed;


}
// #root{
//   // left: 0;
//   width: 100%; /* Cover the entire container */
//   // min-height: 100%;
//   background-image: url('./assets/theme6/bgart.png'); /* Specify the URL of your transparent image */
//   background-color: #FFC05C; /* Specify the color overlay (here, it's red with 50% opacity) */
//   opacity: 1; /* Adjust the opacity of the image */
//   background-size: contain;
//   // background-repeat: repeat;
//   background-position: center;

// }
body {
  background: #D2E6EE;
  font-family: 'Gantari', sans-serif;
  width: 100%;
  margin: 0px;
  padding: 0px;
  // border:2px solid black;
}

body::-webkit-scrollbar {
  display: none;
}

.carousel * {
  height: 70px;

}

.control-dots {
  margin: -100px 0px !important;
}

.main_div {
  background: #D2E6EE;
  height: 100%;


  .banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  .banner_sub_heading_mission {
    display: none;
  }

  .starting_text,
  .mission_starting_text {
    display: none !important;
  }



  .theme__banner p {
    display: none;
  }

  .categories_sec {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: scroll;
    white-space: nowrap;
    background: #22819B;
    height: 223px;
    // min-width:440px;
    margin: -20px 15px 0px 15px;
    border-radius: 10px;
    padding: 30px 25px 15px 15px;
    gap: 18px;

    .categorie {
      min-width: 111px;
      height: 170px;
      background: #fff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 15px;

      .categorie_img {
        background-color: rgba(249, 207, 207, 0.2);
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;


      }

      .categorie_img img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
      }

    }

  }

  .categories_sec::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .categories_sec {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .bottom_text {
    margin: 20px 15px 10px 15px;
    font-size: 2.3rem;
    font-family: 'Gantari', sans-serif;
    text-align: center;
    color: #BCCFD3;

    .color_text {
      color: #3096AE;
    }
  }

  .jingle_text {
    // width: 265px;
    margin: 10px 15px 50px 15px;
    font-size: 16px;
    font-family: 'Gantari', sans-serif;
    text-align: center;
    color: #9CAEB2;
    line-height: 108%;
  }



  // .view_button{
  //   color:#22819B;
  //   padding:"55px 10px";
  //   border:"5px solid #22819B"
  // }
  .trending__heading {
    display: none;
  }



  .mission_sec {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: hidden;
    white-space: nowrap;
    background: #22819B;
    min-height: 80vh;
    margin: -15px 15px 10px 15px;
    border-radius: 10px;
    padding: 20px 15px 15px 15px;
    //  gap: 20px;

    .mission {
      min-width: 330px;
      height: 170px;
      background: #fff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 15px;

      .first_section {
        display: flex;
        flex-direction: row;
        border: 2px solid red;
        width: 30%;
        border: 2px solid green;

        .mission_img {
          background-color: rgba(249, 207, 207, 0.2);
          border-radius: 50%;
          min-width: 61px;
        }

      }

      .second_section {

        width: 70%;
        border: 2px solid blue;

      }


    }

  }
}


// css for sub cat

.main_div_sub {
  background: #D2E6EE;


  .banner_sub {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-bottom:0px;

    // .banner_img_top{
    //     margin-bottom:-30px ;
    // }
  }

  .banner_sub_heading {
    display: none
  }

  .starting_text {
    display: none;
  }



  .categories_sec_sub {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // align-items: start;
    flex-wrap: wrap;
    // overflow-x: scroll;
    white-space: nowrap;
    background: #fff;
    // height: 250px;
    // border:2px solid red;
    // width:100%;
    margin: -20px 15px 10px 15px;
    border-radius: 10px;
    padding: 40px 15px 15px 15px;
    gap: 10px;
    width: 95%;

    >div:nth-child(3n+1) {
      // background-color: red;
      // background-image: linear-gradient(45deg, rgba(62, 16, 79, 0.9), rgba(123, 36, 154, 0.9),rgba(86, 13, 131, 0.9)), url("https://d1mon18jetsxtl.cloudfront.net/elipse_1687427058069-1aknqkvzlj6mpp79.png");
      // background-color: rgba;
      background-color: rgba(62, 16, 79, 1);
      background-image: url("./assets/img/elipse.png");


    }

    >div:nth-child(3n+2) {
      // background-color: blue;
      // background-image: linear-gradient(rgba(79, 61, 16, 1)), url("https://d1mon18jetsxtl.cloudfront.net/elipse_1687427058069-1aknqkvzlj6mpp79.png");
      background-color: (rgba(79, 61, 16, 1));
      background-image: url("./assets/img/elipse.png");


    }

    >div:nth-child(3n) {
      // background-color: green;
      // background-image: linear-gradient(rgba(79, 16, 16, 1)), url("https://d1mon18jetsxtl.cloudfront.net/elipse_1687427058069-1aknqkvzlj6mpp79.png");
      background-color: rgba(79, 16, 16, 1);
      background-image: url("./assets/img/elipse.png");


    }

    .categorie_sub {
      // border:2px solid red;
      // min-width: 170px;
      // background-color: rgba(106, 56, 239, 0.1);
      // background-color:linear-gradient(to bottom, #3E104F, #7B249A, #560D83);
      // background-image: url("./assets/img/elipse.png");
      // background: linear-gradient(45deg, #3E104F, #7B249A, #560D83);

      // background-image: linear-gradient(45deg, rgba(62, 16, 79, 0.9), rgba(123, 36, 154, 0.9),rgba(86, 13, 131, 0.9)), url("https://d1mon18jetsxtl.cloudfront.net/elipse_1687427058069-1aknqkvzlj6mpp79.png");
      background-size: cover;
      background-position: center;
      position: relative;
      width: 48%;
      height: 196px;
      border-radius: 8px;
      display: flex;
      // flex-basis: 42%;
      flex-wrap: nowrap;
      color: #fff;


      flex-direction: column;
      align-items: start;
      justify-content: space-around;
      gap: 20px;
      // overflow: hidden;
      padding: 0px 15px;
      // &:last-child{
      //   align-self: flex-start;
      // }




      .heading_sub {
        color: inherit !important;
        font-size: 25px;
        font-weight: 600;
        white-space: normal;
        overflow-wrap: break-word;
      }

      .link_sub {
        color: #fff;
        font-size: 12px
      }

    }


  }


  .subcategory_heading {
    position: absolute;
    top: 129px;
    // z-index:2;
    font-weight: 900 !important;
    font-family: 'Gantari', sans-serif;
    font-style: italic;
    font-size: 24px;
    color: #fff
  }


  .mission_sec_sub {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: scroll;
    white-space: nowrap;
    background: #22819B;
    // height: 390px;
    margin: -20px 15px 10px 15px;
    border-radius: 10px;
    padding: 10px 25px 15px 15px;
    //  gap: 20px;

    .mission_sub {
      min-width: 330px;
      height: 170px;
      background: #fff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 15px;

      .first_section_sub {
        display: flex;
        flex-direction: row;
        border: 2px solid red;
        width: 30%;
        border: 2px solid green;

        .mission_img_sub {
          background-color: rgba(249, 207, 207, 0.2);
          border-radius: 50%;
          min-width: 61px;
        }

      }

      .second_section_sub {

        width: 70%;
        border: 2px solid blue;

      }


    }

  }
}

.category_button {
  border: 1px solid rgba(34, 129, 155, 1);
  border-radius: 6px;
  color: rgba(34, 129, 155, 1);
  text-transform: capitalize;
  font-weight: 700;
  padding: 10px 20px;
  background: transparent
}

.view_more {
  color: #fff;
  text-decoration: underline;
  margin-top: 10px;
  font-family: 'Gantari', sans-serif;
  padding-bottom: 4px;

}

//css


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wrapper {
  border-radius: 1rem;
  background-color: #22819B;
  overflow-x: hidden;
  display: flex;
  width: 95vw;
  margin: 10px 10px 10px 10px !important
}

.continer {
  /* height: 100vh; */
  /* margin-top: 5%;
    // margin-bottom: 50%;
    margin-left: 5%;
    margin-right: 5%; */
  margin: 5px 5%;
  margin-bottom: 10px;
  background-color: white;
  border-radius: .5rem;
  border: 5px solid white;
  padding: 2.5%;
  overflow-x: hidden;
  width: 85vw;


}


.container1 {
  /* height: 50%; */
  display: flex;
  gap: 0.8rem;
  padding: 1%;
  /* overflow: hidden; */
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.image_div {
  // width: 25%;
  height: 100px;
  width: 100px;
  margin-left: -12px;
  border-radius: 100%;
  margin-left: -12px;
  overflow: hidden;

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 400px) {
    width: 70px;
    height: 70px;
    // border: 1px solid #F8E1E3;
  }

  @media screen and (max-width: 300px) {
    width: 50px;
    height: 50px;
    // border: 1px solid #F8E1E3;
  }
}

.item_container {
  width: 65%;
  /* height: 120%; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  /* gap: 10%; */
}

.fast_food_detail_container {
  display: flex;
  gap: 3px;
  width: 180px;
  height: 14px;
  justify-content: space-between;
  align-items: center;
  // border:2px solid red;
}

.food_detils {
  // width: 43px;
  height: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #B6B6B6;
  font-size: 13px;
}

.food_image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  aspect-ratio: 1;

  @media screen and (max-width: 400px) {


    border: 1px solid #F8E1E3;
  }

  @media screen and (max-width: 300px) {


    border: 1px solid #F8E1E3;
  }
}

.button_9 {
  -webkit-appearance: button;
  appearance: button;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #6a38ef;
  border-radius: 6px;
  border-width: 0;
  box-shadow: inset 0 0 0 1px rgba(50, 50, 93, .1), 0 2px 5px 0 rgba(50, 50, 93, .1), 0 1px 1px 0 rgba(0, 0, 0, .07);
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Gantari;
  font-size: 14px;
  font-style: normal;
  height: 35px;
  line-height: 1.15;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-transform: none;
  touch-action: manipulation;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: all .2s, box-shadow .08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  width: 100%;
}

.button_9:disabled {
  cursor: default;
}

.button_9:focus {
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 6px 15px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
}

.Cash_bonanza_div {
  font-family: 'Gantari';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 19px;
  /* UI Colors/Text Primary Color */
  color: #2C2C2C;
  font-family: 'Gantari';

}

.Fast_food_div {
  font-family: 'Gantari';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin: 5px 0;
  color: #22819B;
}

.offer_div {
  position: absolute;
  z-index: 1;
  margin-left: 15%;
  /* right: .rem; */
}



// mission details css



.boxCover {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
}

.categoryContainer,
.quizContainer,
.subcatContainer {
  width: 100%;
  background-color: #22819B;
  border-radius: 10px;
  padding: 10px 10px;
  position: absolute;
  margin-top: 98px;
}

.subcatContainer {
  background-color: white;
}

.heightOne {
  height: 3px;
  position: relative;
}

.headingImage {
  width: 100%;
  max-width: 190px;
  height: auto;
  position: absolute;
  bottom: -100%;
  left: 50%;
  transform: translateX(-50%);
}

.categorySlider {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 20px 0 0px;
  position: relative;
}

.allCategory {
  display: flex;
  overflow: scroll;
}

.allCategory::-webkit-scrollbar {
  display: none;
}

.category {
  margin: 0 9px 0 0;
  padding: 15px 25px 15px 25px;
  background-color: white;
  border-radius: 8px;
  text-align: center;
}

.categoryImage {
  width: 60px;
  height: 60px;
  justify-content: center;
  margin: 0 auto;
  background-color: rgba(249, 207, 207, 0.2);
  border-radius: 100%;
}

.category h4 {
  font: 500 17px/1 "Montserrat", sans-serif;
  margin-top: 20px;
}

.viewBox {
  display: inline-block;
  margin-top: 18px;
  border: 1px solid rgba(34, 129, 155, 1);
  border-radius: 8px;
  padding: 10px 13px;
  color: rgba(34, 129, 155, 1);
  font: 700 14px/17px "Montserrat", sans-serif;
}

.quizCover {
  padding: 20px 0 0px;
  position: relative;
  width: 100%;
}

.quizBox {
  width: 100%;
  border-radius: 4px;
  background-color: white;
  padding: 10px;
  box-sizing: border-box;
}

.reward {
  width: 100%;
  background-color: rgba(106, 56, 239, 1);
  padding: 12px 8px;
  font: 700 14px/16px "Gantari", sans-serif;
  border-radius: 4px;
  text-align: center;
}

.reward p {
  color: white;
}

.quizInfo {
  width: 100%;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.quizImage {
  width: 90px;
  margin: 0 10px 0 0;
  height: 90px;
  box-sizing: border-box;
  background-color: rgba(248, 225, 227, 1);
  border-radius: 100%;
  justify-content: center;
}

.quizImage img {
  border-radius: 50%;
}

.quizdesc {
  width: calc(100% - 70px);
  box-sizing: border-box;
}

.quizName {
  width: 100%;
  font-family: "Gantari", sans-serif;
  font-weight: 600;
  font-size: 22px;
}

.catName {
  width: 100%;
  font-family: "Gantari", sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin: 5px 0;
  color: #22819b;
}

.quizTime {
  width: 100%;
  justify-content: space-between;
}

.points {
  font: 400 12px/17px "Montserrat", sans-serif;
  position: relative;
  width: 25%;
  color: rgba(182, 182, 182, 1);
}

.points::after {
  content: "";
  position: absolute;
  height: 10px;
  width: 0px;
  top: 50%;
  transform: translateY(-50%);
  right: -10%;
  border-right: 1px solid rgba(182, 182, 182, 1);
}

.points:last-child::after {
  display: none;
}

.points img {
  margin-right: 5px;
}

.subCats {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 10px 0;
}

.subCats .parts {
  width: 45%;
  box-sizing: border-box;
  padding: 15px 12px;
  min-width: 176px;
  border-radius: 10px;
  margin: 10px 0;
  background-size: cover;
}

.parts h3 {
  width: 100%;
  color: white;
  margin-bottom: 70px;
  word-wrap: break-word;
  font: 600 23px/26px "Montserrat", sans-serif;
}

.viewMore {
  margin-bottom: 10px;
}

.viewMore p {
  color: white;
}

.viewMore img {
  margin-left: 5px;
}

.oneQuizCover {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #23819b;
  max-height: 100vh;
  position: fixed;
}

.quizHeader {
  // margin-top:10px;
  padding: 15px 10px;
  width: 100%;
  background-color: white;
  border-radius: 0 0 30px 30px;
}

.mt20 {
  margin-top: 20px;
  margin-left: 14px;
}

.oneQuizInfo {
  padding: 20px 10px;
}

.pointsBox {
  justify-content: space-between;
}

.heroPoint {
  width: 29%;
  background-color: #4494aa;
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  gap: 10px;

}

.heroPoint h5 {
  width: 100%;

  color: white;
  font: 500 18px/22px "Gantari", sans-serif;
}

.heroPoint img {
  margin: 5px;
  width: 20px;
}

.infoPoint {
  
  margin: 15px 0;
  font: 500 18px/22px "Gantari", sans-serif;
  .infodiv{
    @media (max-height:670px) {
        height: 150px!important;
        overflow-y:scroll;

        // border:2px solid green;
  
    }
  }

}

.infoPoint p {
  width: fit-content;
  color: white;
  font: 500 18px/22px "Gantari", sans-serif;
  // height: 26PX;
  // overflow-y: scroll;
  padding-left: 0px;
}



.oneQuizReward {
  position: absolute;
  bottom: 15%;
  margin: 0 auto;
  width: 95%;
  right: 0;
  left: 0;
  box-sizing: border-box;
  border-radius: 6px;
}

.timeBox {
  width: 100%;
  height: 100vh;
  border: 1px solid black;
  background-size: cover;
  position: relative;
}

.timeLeft {
  position: absolute;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  background-color: white;
  border-radius: 100%;
  border: 10px solid yellow;
  top: 50%;
  left: 50%;
  outline: 10px solid white;
  transform: translate(-50%, -50%);
}

.timeLeft h2 {
  color: rgba(219, 38, 114, 1);
  font: 700 35px/35px "Montserrat", sans-serif;
}

.questionBox {
  width: 100%;
  height: 100vh;
}

.quesWarning {
  padding: 10px 15px;
  height: 100%;
  background-color: #23819b;
  width: 100%;
  position: relative;
}

.quesWarning h3 {
  color: white;
}

.quesWarning .proceed {
  position: absolute;
  bottom: 5%;
  margin: 0 auto;
  width: 95%;
  right: 0;
  left: 0;
}

.questionTimeLeft {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  background-color: white;
  border-radius: 100%;
  margin: 0 auto;
  border: 10px solid yellow;
  outline: 10px solid white;
}

.quesImage {
  text-align: center;
  padding-top: 30px;
}

.quesImage img {
  width: 90%;
  margin: 30px auto 0;
}

.flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px
}


.timepopup {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

//css for audiocomp




.video-container {
  position: relative;
  display: flex;
  align-items: center;
  object-fit: cover;
  min-width: 5%;

}

#vect {

  position: absolute;
  left: 8rem;

}

#vect {
  z-index: 1;


}



.back_ground_img {
  overflow: hidden;

}

@media screen and (105px <=width < 453px) {
  #vect img {
    height: 50%;
    width: 50%;
    padding: auto;
  }
}

@media screen and (width=280px) {
  #vect img {
    height: 50%;
    width: 50%;
    padding: auto;
    transform: translate(-80%, -1%);
  }
}

//css for checkcomp


.check-boxes label {
  cursor: pointer;
  // margin-left: 10px;


  display: flex;


}

.check-boxes input[type='checkbox'] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.check-boxes label::before {
  content: '';
  width: 1em;
  height: 1em;
  border-radius: .15em;
  margin-right: 1em;
  border: .05em solid black;
  background-color: white;
}

.check-boxes label:hover::before,
.check-boxes input[type='checkbox']:hover+label::before {
  background-color: white;

}

.check-boxes input[type='checkbox']:checked+label::before {
  content: '\002714';
  background-color: #3EA31B;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;


}


input[type="checkbox"]:checked+label {
  font-weight: bold;
}


// css for layout comp

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.wrapper_layout {
  min-height: 97vh;
  width: 100vw;
  margin: 0 auto;
  background-color: #D2E6EE;
  // overflow-x: hidden;
  // overflow-y: auto;
  // position: relative;


}

.cszbo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Gantari';
  font-style: normal;
  // font-weight: 300;
  font-size: 14px;
  line-height: 2.5rem;
  color: #22819B;
  height: 100%;
}

hr {
  border: 1px solid #BEDEBE;
}

.container_layout {
  // min-height: 100vh;
  /* max-width: 80%; */
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.quesind-timer {
  display: flex;
  justify-content: space-between;
}

.question {
  /* width: 50%; */
  font-family: 'Gantari';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 129.19%;
  margin-bottom: 20px;

}

.que-indicator {
  display: flex;

}

.indicator-color {
  font-family: 'Gantari';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 152.19%;
  color: #22819B;


}

.divide-totalno-ques {
  font-family: 'Gantari';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 152.19%;
  color: #22819B80;

}

.pink-button {
  display: flex;
  justify-content: center;
  background-color: #e84949;
  padding: 0.8rem 2.8rem;
  color: white;
  font-size: 18px;
  box-shadow: 5px 5px 7px 0px #0000003f;
  // position: relative;
  z-index: 1;
  width: 10%;
  cursor: pointer;
  border: 1px solid rgba(34, 129, 155, 1);
  border-radius: 6px;
  // color:rgba(34, 129, 155, 1);
  text-transform: capitalize;
  font-weight: 700;
  margin: 20px 0px;

}

.pink-button::before {
  content: "";
  background-color: #1f1f1f;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: all 1s;
  z-index: -1;
}

// css for progress bar

.progess-bar {
  width: 100%;
  height: .5rem;
  border-radius: .7rem;
  background-color: #F5F5F5;
  margin-bottom: .8rem;
}

.progress-bar-fill {
  height: 100%;
  border-radius: .7rem;
  background-color: #22819B;
  transition: 1s ease-out;
}

//css for qna

*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main {
  width: 100%;
  height: 100vh;
  border: 1px solid black;
  background-size: cover;
  position: relative;

  background-image: url("./assets/icon/timeBack.png");
  /* background: linear-gradient(#e8f0f7 50%, #4d5bf9 50%); */
}

.container {
  height: 300px;
  width: 300px;
  background-color: transparent;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 8px;
  background-image: url("./assets/img/circle.png");
  background-size: cover;
  // object-fit: contain;
  /* box-shadow: 20px 20px 40px rgba(60, 60, 150, 0.25); */
  display: grid;
  place-items: center;
}

.circular-progress {
  position: relative;
  height: 250px;
  width: 250px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  --primary-color: rgba(34, 129, 155, 1);
  --secondary-color:
    rgba(34, 129, 155, 0.17);
}

.circular-progress:before {
  content: "";
  position: absolute;
  height: 84%;
  width: 84%;
  background-color: #ffffff;
  border-radius: 50%;

  --primary-color: rgba(34, 129, 155, 1);
  --secondary-color:
    rgba(34, 129, 155, 0.17);
}

.value-container {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-weight: 700px;
  font-size: 87px;
  color: #db2672;
}

//css for radio comp

.input-radio label,
input {
  cursor: pointer;
  padding: 5px;
  margin: .5rem;
  margin-bottom: 1em;
  transform: scale(2);

}

.input-radio [type="radio"] {

  accent-color: #3EA31B;


}

input[type='radio']:checked+label {
  accent-color: #3EA31B;
  font-weight: bold;

}


//css for spinner



*,
*::after,
*::before {
  box-sizing: border-box;
}

.spinner_contianer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  overflow: hidden;
  position: relative;
  animation: text-color 2s ease-in-out infinite alternate;
}

.spinner-sector {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 15px solid transparent;
  mix-blend-mode: overlay;
  animation: rotate var(--duration) var(--timing) infinite;
  pointer-events: none;
}

.spinner-sector-red {
  border-top-color: lightgreen;
  --duration: 1s;
  --timing: ease-in-out;
}

.spinner-sector-blue {
  border-left-color: lightgreen;
  --duration: 1s;
  --timing: ease-in-out;
}

.spinner-sector-green {
  border-right-color: lightgreen;
  --duration: 1s;
  --timing: ease-in-out;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  33.33% {
    transform: rotate(90deg);
  }

  66.66% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes text-color {
  0% {
    color: rgba(0, 0, 0, 1);
  }

  50% {
    color: rgba(0, 0, 0, .5);
  }

  100% {
    color: rgba(0, 0, 0, .1);
  }
}

.go_div {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 87px;
  line-height: 130px;
}

//css for timer

.timer {
  font-family: 'Gantari';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 152.19%;
  color: #267826;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pau_icon {
  display: flex;
  cursor: pointer;
  transform: scaleX(1.2);
  transition: all 1s;
  gap: 1rem;
}

.play {
  background-color: whitesmoke;
}

.pause {
  background-color: white;
}

//css for video comp




.video-container {
  position: relative;
  display: flex;
  align-items: center;
  object-fit: cover;
  min-width: 5%;

}

#vect {

  position: absolute;

  left: 8rem;

}

#vect {
  z-index: 1;


}



.back_ground_img {
  overflow: hidden;

}

@media screen and (105px <=width < 453px) {
  #vect img {
    height: 50%;
    width: 50%;
    padding: auto;
  }
}

@media screen and (width=280px) {
  #vect img {
    height: 50%;
    width: 50%;
    padding: auto;
    transform: translate(-80%, -1%);
  }
}


// css for spinner




.wrapper_success {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  background-image: url('./assets/icon/timeBack.png') !important;
  background-size: cover;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper_success_theme4,
.wrapper_unsuccess_theme4 {
  display: none;
}



.container_success {
  /* margin: 5%; */
  // display: grid;
  // grid-gap: 30%;
  // place-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image_container_success {
  /* border:10px solid black; */
  display: grid;
  place-items: center;
}

.btn_success {
  position: fixed;
  background: #22819b;
  border-radius: 6px;
  padding: 13px 139px;
  gap: 10px;
  font-family: "Gantari";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  bottom: 20px;
}

.text_container_success {
  display: grid;
  grid-gap: 20%;
  font-family: "Gantari";
  font-style: normal;
  font-weight: 700;
  font-size: 100%;
  line-height: 86%;
  text-align: center;
  color: #51625a;
  margin-bottom: 170px;
}

.rocket_color {
  color: #3096AE;
}


#qns_image {
  width: 100%;
  height: 220px;
  border-radius: 9px;

}

#video {
  width: 90%;
  height: 50%;


}



.mobile__width {
  width: 100%;
}

.bg_color {
  background-color: #fff;
  padding-top: 10px;

}

.bg_color_history{
  background: rgba(34, 129, 155, 0.17)  }


.history_div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-image: linear-gradient(126deg, #589fb3 22.82%, #589fb3 88.59%);

  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
  width: 54px;
  height: 40px;
  cursor: pointer;

}

.profile_div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 2px solid #fff;
  outline: 2px solid #589fb3;
  border-radius: 50%;
  flex-shrink: 0;
  background-image: linear-gradient(126deg, #589fb3 22.82%, #589fb3 88.59%);
  cursor: pointer;

  a {
    text-decoration: none;

    p {
      color: #fff;
    }
  }


}

.profile__section {
  // position: absolute;
  // height: 30vh;
  width: 120%;
  // border:2px solid red;
  background: #589fb3;
  background-image: url("./assets/img/profile_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0px 0px 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;
  // gap: 5vw;
//   background: linear-gradient(to right,

//   rgba(67, 108, 255, 0.3) 50%,
//   /* First color with blur */

//   rgba(251, 67, 255, 0.3) 100%
//   /* Second color with blur */
// ); 
  
  .round__profile__section {
      position: relative;
      padding-top: 280px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      width: 85%;
      
      background: linear-gradient(146deg, #436CFF 2%,#ffffff 56%, #FB43FF 388%);
      
   
      border-radius: 0px 0px 100% 100%;
      margin-top: -50%;
      
      opacity: 1;
      // background: radial-gradient(circle, rgba(255, 255, 255, 0.9) 30%, rgba(255, 0, 0, 0.5) 0%, rgba(0, 0, 255, 0.5) 100%);  
      gap: 2vh;
      padding-bottom:70px;
      .profile_div{
          p{
              color:#fff;
              font-size: 28px;
          }
      }

  }

  .profile__name {
    color: #000;
    font-family: Roboto!important;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 26px */
    letter-spacing: 0.52px;
    // margin-top:65vh;
  }

  .profile__detail {
    width: 80%;
    height: 10vh;
    border-radius: 13px;
    background: #FFF;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: center;
    color: #FF6578;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.49px;
  }



}

.profile__detail__section {
  margin-top: 40px;
  width: 90%;
  // border:2px solid red;
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  gap: 15px;
  color: #DD3F52;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.49px;

  .p_detail {
    width: 100%;
    display: flex;
    gap: 15px;
    background: #FFF;
    height: 50px;
    align-items: center;
    border-radius: 13px;
    padding-left: 10px;
    width: 100%;
    background: linear-gradient(to right,

        rgba(67, 108, 255, 0.1) 50%,
        /* First color with blur */

        rgba(251, 67, 255, 0.1) 100%
        /* Second color with blur */
      );
    // filter: blur(15.5px) blur(13px);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);

  }
}

.history__heading {
  display: flex;
  justify-content: space-between;
  // border:2px solid red;
  align-items: center;
  width: 80%;
  margin: 35px 0px 20px 0px;

}

.no__transaction{
  height: 25vh;
  color:#000;
  display: flex;
  align-items: center;
  justify-content: center;

}
.no__transaction__history{
    width: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.history__card {
  display: flex;
  margin: 5px 10px;
  border-radius: 20px;
  background: #FCFCFC;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
  width: 90%;
  height: 92px;
  padding: 15px;


  .history__pic {
    border-radius: 11.514px;
    background: rgba(34, 129, 155, 0.17);
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }
  }

  .history__details {
    width: 75%;
    height: 100%;
    padding: 0px 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    // justify-content: space-around;


    .top {
      height: 19px;
      display: flex;
      justify-content: space-between;
      // align-items: start;

      .name {
        color: #404040;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.32px;
      }

      .logo {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap:5px;


      }
    }

    .middle {
      color: #8E54E9;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      color: #7D7D7D;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 12px */
    }


  }

}

.next__page {
  margin-top: 20px;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
  
  background: #22819B;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration-line: underline;
  text-decoration-color: #22819B;
  margin-bottom: 20px;



  p {

    text-decoration: underline;
    
  }
}




@media screen and (min-width: 700px) {

  /* Add your styles here */
  #qns_image {
    width: 30%;


  }

  #video {
    width: 30%;
    height: 20%;


  }
}

@media (min-width: 821px) {

  body {
    width: 30%;
    background-color: white;
  }

  .dailog {
    width: 50%;
    border: 2px solid red;
  }

  .continer {
    width: 100%;
    // background-color: white;
  }

  .wrapper {
    width: 111%;
  }

  .oneQuizCover {
    width: 30%;
  }

  .wrapper_layout {
    width: 100%;
  }

  .wrapper_success {
    width: 100%;
  }

  #qns_img {
    width: 100%;
  }

  .main_div {
    width: 100%;
    min-height: 100vh;
    background: #D2E6EE;
  }

  .main_div_sub {
    width: 100%;
    min-height: 100vh;
    padding-bottom: 10px;

  }

  .timepopup {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #appbar {
    width: 100%
  }

  .mobile__width {
    width: 30%;
  }

  #image_div {
    width: 100%;
  }

  #qns_image {
    width: 100%;


  }

  #video {
    width: 100%;
  }



  .css-18uy99e-MuiStack-root {
    width: 30vw !important;
  }

  .css-1ggrdok {
    width: 30vw !important;
  }

}

.maintinance__parent{
  //  font-family: lato;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:10px;
    background-color: #fff;
    text-align: center;
    gap:40px;
    h2{
      font-size: calc(50px + 0.5vw);
  
    }
    h3{
      font-size: calc(20px + 0.5vw);
    
  
    }
    img{
      width: 100%;
    }
  }

  .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    border-radius: 20px!important;
  }
  
  .css-11gvj1t-MuiTypography-root-MuiDialogContentText-root {
    margin: 0;
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.6);
  }

  .dailogue_bg{
    background: linear-gradient(179deg, #4776E6 0%, #8E54E9 100%);;
    }

   

    .sidebar_left{
      background-color:transparent;
      height:100vh;
      width:40%;
      z-index:8;
      position:absolute;
      left: 0px;
      top: 0px;

    
    }

    .sidebar{
      background-color:#22819B;
      height:100vh;
      width:60%;
      z-index:8;
      position:absolute;
      right: 0px;
      top: 0px;

      // @media (min-width: 821px) {
      //   width: 50%;
      // }
      // border:12px solid green;
      .body{
        // border: 2px solid green;
        margin:20px 0px 0px 20px;
        h2{
          margin-bottom: 30px;
        }
        ul{
          
          list-style: none;
          tr{
            display: flex;
            gap:10px
          }
        }
      }
    }

    .divider{
      color:#000;
      background-color: #000;
      border:2px solid #fff;
    }