@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');


.container {
  width: 100vw;
  /* Adjust width as needed */
  height: 100vh;
  /* Adjust height as needed */
  // position: relative; /* Ensure proper positioning for overlay */
  // border: 20px solid blue;

  position: absolute;
  /* Position the pseudo-element */
  top: 0;
  left: 0;
  /* Cover the entire container */

  background-image: url('../../../../assets/theme6/bgart.png');
  /* Specify the URL of your transparent image */
  background-color: #FFC05C;
  /* Specify the color overlay (here, it's red with 50% opacity) */
  opacity: 1;

  display: flex;
  // align-items: center;

  // justify-content: center;
  flex-direction: column;
  gap: 10%;
  // background-color: #FFC05C;
  border-right: 2px solid black;

  @media (min-width:821px) {
    width: 30vw;
  }


  .logo {
    position: absolute;
    top: 3vh;
    left: 3vh;
    // border:2px solid green;
    z-index: 2;
  }

  .success__img {
    // background-image: url('../Animation/successAnimation.jsx');
  }

  .middle {
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
  }


}

.container::before {
  content: "";
  /* Create a pseudo-element */
  /* Adjust the opacity of the image */
  // background-repeat: repeat;
}


.btn {
  width: 196px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 11px;
  border: 0.932px solid rgba(0, 0, 0, 0.00);
  background: #FA5743;
  box-shadow: 0px 3px 3.1px 0px #302955;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  p {
    color: #FFF;
    font-family: "Luckiest Guy";
    font-size: 15.061px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}


.btn__home {
  width: 196px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 11px;
  border: 0.932px solid rgba(0, 0, 0, 0.00);
  background: #FFF;
  box-shadow: 0px 3px 3.1px 0px #302955;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  p {
    color: #FA5743;
    font-family: "Luckiest Guy";
    font-size: 15.061px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
  }
}

.try__div {
  position: relative;
  top: -70px
}


.btn {
  width: 233px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 11px;
  border: 0.932px solid rgba(0, 0, 0, 0.00);
  background: #FA5743;
  box-shadow: 0px 3px 3.1px 0px #302955;
  position: relative;
  top: -50px;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;

  p {
    color: #FFF;
    font-family: "Luckiest Guy";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 8px;
  }
}


.reward {
  position: relative;
  top: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  .rewardLine {
    position: absolute;
    width: 70%;
    bottom: 25px;
    background-image: linear-gradient(97deg, #1DB75A 8.08%, #16974A 75.03%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 14px;
  }

}