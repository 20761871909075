// body{
//   width: 100%;
// }
.main_div_sub {
      background:rgba(85, 61, 129, 1) ;
    // background: linear-gradient(180deg, rgba(142, 84, 233, 0.08) 0%, rgba(142, 84, 233, 0.00) 100%), linear-gradient(184deg, rgba(71, 118, 230, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
    min-height: 100vh;
    width: 100vw;
    position: absolute;
    top:0;
    overflow: hidden;
    margin-top: 100px;
    border-top-left-radius: 30px; /* Set border radius for top-left corner */
  border-top-right-radius: 30px;

  @media (min-width:821px){
    width:30% ;
  }
 
  
    .banner_sub {
      display: none;
      //   display:flex;
      //   flex-direction:column;
      //   justify-content:center; 
      //   align-items:center; 
      // margin-bottom:0px;
  
      // .banner_img_top{
      //     margin-bottom:-30px ;
      // }
    }
  
    .starting_text {
      display: block;
    }
  
    .banner_sub_heading {
      display: block;
      margin-top: 30px;
      margin-left: 30px;
    }
  
    .banner_sub_heading p {
  
        color: #FFF;

        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
  
    }
  
  
    .starting_text {
     
      /* 22px */
      letter-spacing: 0.44px;
      margin-left: 30px;
      margin-top: 10px;
      color: #FFF;

font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: normal;
    }
  
  
  
  
  
  
  
  
    .categories_sec_sub {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    //   white-space: nowrap;
      background: transparent;
      margin: -20px 15px 10px 10px;
      border-radius: 10px;
      padding: 40px 15px 15px 15px;
      gap: 10px;
      width: 95%;
      overflow: hidden;

      >div:nth-child(odd){
        color:#414141
      }

     
  
      >div:nth-child(8n+1) {
        
        background-color: #EAD0AF;
  
  
      }
  
      >div:nth-child(8n+2) {
        background-color: #A07959;
  
  
      }
  
      >div:nth-child(8n+3) {
        background-color: #F3A9E8;
  
  
      }

      >div:nth-child(8n+4) {
        background-color: #EF5F99;
  
  
      }

      >div:nth-child(8n+5) {
        background-color: #7FC659;
  
  
      }

      >div:nth-child(8n+6) {
        background-color: #CFE26F;
  
  
      }


      >div:nth-child(8n+7) {
        background-color: #64D1D6;
  
  
      }

      >div:nth-child(8n+8) {
        background-color: #3379AA;
  
  
      }
  
      .categorie_sub {
        position: absolute;
        background-size: cover;
        background-position: center;
        position: relative;
        width: 48%;
        height: 195px;
        border-radius: 18px;
        display: flex;
        flex-wrap: nowrap;
        color: #fff;
        border:2px solid green;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        gap: 20px;
        padding: 0px 15px;

        border-radius: 15.842px;
border: 0.932px solid rgba(0, 0, 0, 0.37);
background: #EAD0AF;
box-shadow: -1px 0px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 3.1px 0px #302955;
// padding-bottom: -40%;

        
  
  
  
  
        .heading_sub {
          color: inherit !important;
        //   border:2px solid red;
  
          font-family: Roboto;
          font-size: 25.487px;
          font-style: normal;
          font-weight: 600;
          line-height: 108%;
          word-wrap: break-word;
          overflow-wrap: break-word
          /* 27.526px */
        }
  
        .link_sub {
            width: 80%;
            height: 40px;
            border-radius: 15.842px;
            border: 0.932px solid rgba(0, 0, 0, 0.37);
            background: #FA5743;
            box-shadow: -1px 0px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 3.1px 0px #302955;
          color: #fff;
    
          display:flex;
          align-items: center;
          position: absolute;
          bottom: 0;
          left: 0;
          padding-left: 5%;
          line-height: 108%;
          display: flex;
          align-items: space-between;
          justify-content: space-evenly;
          padding:0px 5px;
          font-family: 'Roboto', sans-serif;
          color: #FFF;
          font-weight: 500;

font-size: 13.2px;
font-style: normal;
font-weight: 500;
line-height: 108%; /* 13.763px */

        }
  
      }
  
  
    }
  
  
    .subcategory_heading {
      position: absolute;
      top: 120px;
      // z-index:2;
      font-weight: 900 !important;
      font-family: 'Gantari', sans-serif;
      font-style: italic;
      font-size: 24px;
      color: #fff
    }
  
  
    .mission_sec_sub {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      flex-wrap: nowrap;
      overflow-x: scroll;
      white-space: nowrap;
      background: #22819B;
      // height: 390px;
      margin: -20px 15px 10px 15px;
      border-radius: 10px;
      padding: 10px 25px 15px 15px;
      //  gap: 20px;
  
      .mission_sub {
        min-width: 330px;
        height: 170px;
        background: #fff;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
  
        .first_section_sub {
          display: flex;
          flex-direction: row;
          border: 2px solid red;
          width: 30%;
          border: 2px solid green;
  
          .mission_img_sub {
            background-color: rgba(249, 207, 207, 0.2);
            border-radius: 50%;
            min-width: 61px;
          }
  
        }
  
        .second_section_sub {
  
          width: 70%;
          border: 2px solid blue;
  
        }
  
  
      }
  
    }
  }



  @media (min-width: 821px) {

    body{
      width: 30%;
      // border: 2px solid green;
    }
           
    .main_div_sub{
      width: 30%;
    }
    // border: 2px solid red;   
  }