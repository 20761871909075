
.circular__bar{
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 0%;
    z-index: 5;
    transform: translate(-50% , 0%);
    color: #fff;
    // border:2px solid green;
}


.container {

    display: flex;
    flex-direction: column;
    width: 100%;
    // border: 2px solid green;
    // gap: 8vh;
    // margin-top: 50px;
    // border: 4px solid blue;
    // width: 100%;
    // height: auto;


    @media (min-width:412px){
    gap: 0vh;
    // width: 100%;

    }


    // @media (min-width:424px){
    //     gap: 20px;
    //     // width: 100%;
    
    //     }

    

    // @media (min-width:320px){
    //     gap: 5vh;
    
    //     }

    // @media (min-width:460px){
    // gap: 5vh;
    // // border:2px solid green;

    // }

    // @media (min-width:510px){
    // gap: 5vh;

    // }

    // @media (min-width:560px){
    // gap: 5vh;

    // }

    // @media (min-width:600px){
    // gap: 5vh;

    // }

    // @media (min-width:660px){
    // gap: 5vh;

    // }

    // @media (min-width:760px){
    //     gap: 5vh;
    
    //     }

    // align-items: f;
    // al
    .Qus__area {
        // width: 95vw;
        // height: 77vh;
        width: 98%;
        max-width: 500px;
        max-height: 550px;
        min-height: 400px;
        margin: 0 auto 0px;
        position: relative;
        
        // margin-bottom: 50px;

        @media (min-width:425px){
margin-bottom: 40px;            
        }

        // @media (min-width:320px){
        //     height:65vh;
            
        // }

        // @media (min-width:380px){
        //     height:66vh;
            
        // }

        // @media (min-width:420px){
        //     height:78vh;
            
        // }

        // @media (min-width:460px){
        //     height:78vh;
            
        // }

        // @media (min-width:662px){
        //     height:83vh;
            
        // }

        // @media (min-width:700px){
        //     height:100vh;
            
        // }

        // @media (min-width:821px){
        //     width:100%;
            
        // }
        // border: 2px solid green;


        .count__number {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: hsla(7, 95%, 62%, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 63%;
            top: 7%;
            transform: translate(-50% , -10%);
            z-index: 1;
            color: #fff;
            
            @media (min-width:360px){
                // top:13%;
                left:65%;
            }
            

            @media (min-width:380px){
                // top:13%;
                left:63.5%;
            }

            @media (min-width:420px){
                // top:15%;
                left:61%;
            }

            p {
                color: #FFF;

                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

       

        .shadow__area__q {
            // background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNDIiIGhlaWdodD0iMzY0IiB2aWV3Qm94PSIwIDAgMzQyIDM2NCIgZmlsbD0ibm9uZSI+CiAgPHBhdGggb3BhY2l0eT0iMC4yNiIgZD0iTTAgMzIuMjI1NUMwIDEzLjM0MTggMTYuMjY2OCAtMS40MzU0NCAzNS4wNjM3IDAuMzcyNDY2TDMwMS44MTQgMjYuMDI4OEMzMTcuNjgzIDI3LjU1NTEgMzMwLjAwOCA0MC41Mjk3IDMzMC43MTggNTYuNDU2M0wzNDEuNjU0IDMwMS43MDJDMzQyLjQxIDMxOC42NDUgMzI5LjgxNiAzMzMuMjM0IDMxMi45NDUgMzM0Ljk2MUwzNS4yNTkxIDM2My4zOUMxNi4zOTU3IDM2NS4zMjEgMCAzNTAuNTE5IDAgMzMxLjU1N1YzMi4yMjU1WiIgZmlsbD0idXJsKCNwYWludDBfcmFkaWFsXzgzMF8yMzc5KSIvPgogIDxkZWZzPgogICAgPHJhZGlhbEdyYWRpZW50IGlkPSJwYWludDBfcmFkaWFsXzgzMF8yMzc5IiBjeD0iMCIgY3k9IjAiIHI9IjEiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIiBncmFkaWVudFRyYW5zZm9ybT0idHJhbnNsYXRlKDE2NC4yMzkgMTk2LjEzMikgcm90YXRlKDUwLjQ5MDgpIHNjYWxlKDI0OC44MzQgMTg1LjQ0NikiPgogICAgICA8c3RvcCBzdG9wLWNvbG9yPSIjNjY0QTg2Ii8+CiAgICAgIDxzdG9wIG9mZnNldD0iMC42NjQ0OTciIHN0b3AtY29sb3I9IiM0MzM1NjYiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjMzAyOTU1Ii8+CiAgICA8L3JhZGlhbEdyYWRpZW50PgogIDwvZGVmcz4KPC9zdmc+");
            // fill: radial-gradient(63.72% 69.44% at 47.88% 53.82%, #664A86 0%, #433566 66.45%, #302955 100%,);
           
            // background-size: cover;
            // background-image: url("data:image/svg+xml,%3Csvg%20width%3D%22100%25%22%20height%3D%22100%25%22%20viewBox%3D%220%200%20352%20370%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20opacity%3D%220.26%22%20d%3D%22M9%2035.2255C9%2016.3418%2025.2668%201.56456%2044.0637%203.37247L310.814%2029.0288C326.683%2030.5551%20339.008%2043.5297%20339.718%2059.4563L350.654%20304.702C351.41%20321.645%20338.816%20336.234%20321.945%20337.961L44.2591%20366.39C25.3957%20368.321%209%20353.519%209%20334.557V35.2255Z%22%20fill%3D%22url(%23paint0_radial_1352_364)%22%2F%3E%3Cpath%20d%3D%22M17.1335%2037.3828C18.1415%2018.8148%2034.7096%205.01324%2053.1541%207.37696L319.068%2041.4547C335.038%2043.5013%20347%2057.0946%20347%2073.1951V321C347%20338.673%20332.673%20353%20315%20353H33.7843C15.4296%20353%200.836373%20337.593%201.83131%20319.265L17.1335%2037.3828Z%22%20fill%3D%22url(%23paint1_radial_1352_364)%22%2F%3E%3Cdefs%3E%3CradialGradient%20id%3D%22paint0_radial_1352_364%22%20cx%3D%220%22%20cy%3D%220%22%20r%3D%221%22%20gradientUnits%3D%22userSpaceOnUse%22%20gradientTransform%3D%22translate(173.239%20199.132)%20rotate(50.4908)%20scale(248.834%20185.446)%22%3E%3Cstop%20stop-color%3D%22%23664A86%22%2F%3E%3Cstop%20offset%3D%220.664497%22%20stop-color%3D%22%23433566%22%2F%3E%3Cstop%20offset%3D%221%22%20stop-color%3D%22%23302955%22%2F%3E%3C%2FradialGradient%3E%3CradialGradient%20id%3D%22paint1_radial_1352_364%22%20cx%3D%220%22%20cy%3D%220%22%20r%3D%221%22%20gradientUnits%3D%22userSpaceOnUse%22%20gradientTransform%3D%22translate(173.5%20199.017)%20rotate(50.5765)%20scale(248.923%20185.404)%22%3E%3Cstop%20stop-color%3D%22%23664A86%22%2F%3E%3Cstop%20offset%3D%220.664497%22%20stop-color%3D%22%23433566%22%2F%3E%3Cstop%20offset%3D%221%22%20stop-color%3D%22%23302955%22%2F%3E%3C%2FradialGradient%3E%3C%2Fdefs%3E%3C%2Fsvg%3E");
            background-image: url('../../../../assets/theme6/qestion.svg');
           background-repeat: no-repeat;
            width: 100%;
            min-height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: center;

            @media (min-width:821px){
                width:100%;
                min-height: 120%;
                
           

                
            }

            @media (min-width:320px){
                min-height: 60%;
                

                
            }

            @media (min-width:460px){
                min-height: 78%;
                

                
            }

            @media (min-width:510px){
                min-height: 80%;
                    
            }

            @media (min-width:560px){
                min-height: 100%;
                // border:2px solid green;
                

                
            }

            @media (min-width:600px){
                min-height: 78%;
               

                
            }

            @media (min-width:760px){
                min-height: 100%;
                   
            }

            // clip-path: polygon(0 0, 100% 6%, 100% 93%, 0 100%);
            // border-radius: 20%;
            // background: radial-gradient(63.72% 69.44% at 47.88% 53.82%, #664A86 0%, #433566 66.45%, #302955 100%);
            // background-color: rgba(0, 0, 0, 0.12);
            // opacity: 0.26;

            // border: 2px solid yellow;

            .main__area__q {
                // background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNDYiIGhlaWdodD0iMzQ2IiB2aWV3Qm94PSIwIDAgMzQ2IDM0NiIgZmlsbD0ibm9uZSI+CiAgPHBhdGggZD0iTTE2LjEzMzUgMzAuMzgyOEMxNy4xNDE1IDExLjgxNDggMzMuNzA5NiAtMS45ODY3NiA1Mi4xNTQxIDAuMzc2OTY0TDMxOC4wNjggMzQuNDU0N0MzMzQuMDM4IDM2LjUwMTMgMzQ2IDUwLjA5NDYgMzQ2IDY2LjE5NTFWMzE0QzM0NiAzMzEuNjczIDMzMS42NzMgMzQ2IDMxNCAzNDZIMzIuNzg0M0MxNC40Mjk2IDM0NiAtMC4xNjM2MjcgMzMwLjU5MyAwLjgzMTMwNiAzMTIuMjY1TDE2LjEzMzUgMzAuMzgyOFoiIGZpbGw9InVybCgjcGFpbnQwX3JhZGlhbF84MzBfMjQ5OCkiLz4KICA8ZGVmcz4KICAgIDxyYWRpYWxHcmFkaWVudCBpZD0icGFpbnQwX3JhZGlhbF84MzBfMjQ5OCIgY3g9IjAiIGN5PSIwIiByPSIxIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgZ3JhZGllbnRUcmFuc2Zvcm09InRyYW5zbGF0ZSgxNzIuNSAxOTIuMDE3KSByb3RhdGUoNTAuNTc2NSkgc2NhbGUoMjQ4LjkyMyAxODUuNDA0KSI+CiAgICAgIDxzdG9wIHN0b3AtY29sb3I9IiM2NjRBODYiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIwLjY2NDQ5NyIgc3RvcC1jb2xvcj0iIzQzMzU2NiIvPgogICAgICA8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiMzMDI5NTUiLz4KICAgIDwvcmFkaWFsR3JhZGllbnQ+CiAgPC9kZWZzPgo8L3N2Zz4=");
                // background-size: contain;
                // // object-fit: cover;   
                // background-repeat: no-repeat;
                // clip-path: path('M16.1335 30.3828C17.1415 11.8148 33.7096 -1.98676 52.1541 0.376964L318.068 34.4547C334.038 36.5013 346 50.0946 346 66.1951V314C346 331.673 331.673 346 314 346H32.7843C14.4296 346 -0.163627 330.593 0.831306 312.265L16.1335 30.3828Z');
                // clip-path: polygon(3% 1%, 100% 5%, 100% 100%, 0 95%);
                // background: radial-gradient(circle,
                //         hsla(268, 29%, 41%, 1) 0%,
                //         hsla(257, 32%, 30%, 1) 50%,
                //         hsla(250, 35%, 25%, 1) 100%);
                // border-radius: 20%;
                width: 100%;
                height: auto;
                // background-color: beige;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap:10%;
                // margin-top: 30px;

                
               

                

                .question__area {
                    width: 80vw;
                    
                    // height: 150%;
                    @media (min-width:821px){
                        width:100%;
                        

                    }
                    p{
                        color: #FFF;
                    font-family: Roboto;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 25px;
                    /* 138.889% */
                    letter-spacing: -0.24px;
                    overflow: hidden;
                    white-space: normal;
                    word-wrap: break-word;
                    height: auto;
                    word-wrap: break-word;
                    overflow-wrap: break-word
                    }
                    
                }

                .file__area{
                    // border:2px solid yellow;
                    width: 80vw;
                    // height: 120px;
                    border-radius: 10px;

                   
                }
            }
        }

        .shadow__area__q__img {
            // background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNDIiIGhlaWdodD0iMzY0IiB2aWV3Qm94PSIwIDAgMzQyIDM2NCIgZmlsbD0ibm9uZSI+CiAgPHBhdGggb3BhY2l0eT0iMC4yNiIgZD0iTTAgMzIuMjI1NUMwIDEzLjM0MTggMTYuMjY2OCAtMS40MzU0NCAzNS4wNjM3IDAuMzcyNDY2TDMwMS44MTQgMjYuMDI4OEMzMTcuNjgzIDI3LjU1NTEgMzMwLjAwOCA0MC41Mjk3IDMzMC43MTggNTYuNDU2M0wzNDEuNjU0IDMwMS43MDJDMzQyLjQxIDMxOC42NDUgMzI5LjgxNiAzMzMuMjM0IDMxMi45NDUgMzM0Ljk2MUwzNS4yNTkxIDM2My4zOUMxNi4zOTU3IDM2NS4zMjEgMCAzNTAuNTE5IDAgMzMxLjU1N1YzMi4yMjU1WiIgZmlsbD0idXJsKCNwYWludDBfcmFkaWFsXzgzMF8yMzc5KSIvPgogIDxkZWZzPgogICAgPHJhZGlhbEdyYWRpZW50IGlkPSJwYWludDBfcmFkaWFsXzgzMF8yMzc5IiBjeD0iMCIgY3k9IjAiIHI9IjEiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIiBncmFkaWVudFRyYW5zZm9ybT0idHJhbnNsYXRlKDE2NC4yMzkgMTk2LjEzMikgcm90YXRlKDUwLjQ5MDgpIHNjYWxlKDI0OC44MzQgMTg1LjQ0NikiPgogICAgICA8c3RvcCBzdG9wLWNvbG9yPSIjNjY0QTg2Ii8+CiAgICAgIDxzdG9wIG9mZnNldD0iMC42NjQ0OTciIHN0b3AtY29sb3I9IiM0MzM1NjYiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjMzAyOTU1Ii8+CiAgICA8L3JhZGlhbEdyYWRpZW50PgogIDwvZGVmcz4KPC9zdmc+");
            // fill: radial-gradient(63.72% 69.44% at 47.88% 53.82%, #664A86 0%, #433566 66.45%, #302955 100%,);
           
            // background-size: cover;
            // background-image: url("data:image/svg+xml,%3Csvg%20width%3D%22100%25%22%20height%3D%22100%25%22%20viewBox%3D%220%200%20352%20370%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20opacity%3D%220.26%22%20d%3D%22M9%2035.2255C9%2016.3418%2025.2668%201.56456%2044.0637%203.37247L310.814%2029.0288C326.683%2030.5551%20339.008%2043.5297%20339.718%2059.4563L350.654%20304.702C351.41%20321.645%20338.816%20336.234%20321.945%20337.961L44.2591%20366.39C25.3957%20368.321%209%20353.519%209%20334.557V35.2255Z%22%20fill%3D%22url(%23paint0_radial_1352_364)%22%2F%3E%3Cpath%20d%3D%22M17.1335%2037.3828C18.1415%2018.8148%2034.7096%205.01324%2053.1541%207.37696L319.068%2041.4547C335.038%2043.5013%20347%2057.0946%20347%2073.1951V321C347%20338.673%20332.673%20353%20315%20353H33.7843C15.4296%20353%200.836373%20337.593%201.83131%20319.265L17.1335%2037.3828Z%22%20fill%3D%22url(%23paint1_radial_1352_364)%22%2F%3E%3Cdefs%3E%3CradialGradient%20id%3D%22paint0_radial_1352_364%22%20cx%3D%220%22%20cy%3D%220%22%20r%3D%221%22%20gradientUnits%3D%22userSpaceOnUse%22%20gradientTransform%3D%22translate(173.239%20199.132)%20rotate(50.4908)%20scale(248.834%20185.446)%22%3E%3Cstop%20stop-color%3D%22%23664A86%22%2F%3E%3Cstop%20offset%3D%220.664497%22%20stop-color%3D%22%23433566%22%2F%3E%3Cstop%20offset%3D%221%22%20stop-color%3D%22%23302955%22%2F%3E%3C%2FradialGradient%3E%3CradialGradient%20id%3D%22paint1_radial_1352_364%22%20cx%3D%220%22%20cy%3D%220%22%20r%3D%221%22%20gradientUnits%3D%22userSpaceOnUse%22%20gradientTransform%3D%22translate(173.5%20199.017)%20rotate(50.5765)%20scale(248.923%20185.404)%22%3E%3Cstop%20stop-color%3D%22%23664A86%22%2F%3E%3Cstop%20offset%3D%220.664497%22%20stop-color%3D%22%23433566%22%2F%3E%3Cstop%20offset%3D%221%22%20stop-color%3D%22%23302955%22%2F%3E%3C%2FradialGradient%3E%3C%2Fdefs%3E%3C%2Fsvg%3E");
            background-image: url('../../../../assets/theme6/questionwithimg.svg');
            background-size: 100%;
            background-position: top;
           background-repeat: no-repeat;
            width: 100%;
            min-height: 490px;
            max-height: 500px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: center;

            @media (min-width:425px){
                background-size: contain 
            }

            // @media (max-width:342px){
            //     min-height: 420px;
            // }
            
           

            @media (min-width:340px){
                min-height: 430px;
                
            }
            @media (min-width:343px){
                min-height: 450px;
                
            }

            @media (min-width:370px){
                min-height: 490px;
                   
            }

            @media (min-width:420px){
                min-height: 514px;
            max-height: 550px;

                   
            }

            // @media (min-width:440px){
            //     min-height: 600px;
                   
            // }

            // @media (min-width:500px){
            //     min-height: 100%;
                    
            // }

            // @media (min-width:540px){
            //     min-height: 108%;
            //     // border:2px solid green;  
                

                
            // }

            // @media (min-width:600px){
            //     min-height: 100%;
               

                
            // }

            // @media (min-width:760px){
            //     min-height: 110%;
                   
            // }

            // clip-path: polygon(0 0, 100% 6%, 100% 93%, 0 100%);
            // border-radius: 20%;
            // background: radial-gradient(63.72% 69.44% at 47.88% 53.82%, #664A86 0%, #433566 66.45%, #302955 100%);
            // background-color: rgba(0, 0, 0, 0.12);
            // opacity: 0.26;

            // border: 2px solid yellow;

            .main__area__q {
        
                width: 95%;
                height: auto;
                // background-color: beige;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                // gap:10%;

                @media (min-width:460px){
                    width: 85%;
                }
           
                .question__area {
                    width: 90%;
                    // height: 150%;
                    @media (min-width:821px){
                        width:100%;
                        

                    }
                    p{
                        color: #FFF;
                    font-family: Roboto;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 25px;
                    /* 138.889% */
                    letter-spacing: -0.24px;
                    overflow: hidden;
                    white-space: normal;
                    word-wrap: break-word;
                    height: auto;
                    word-wrap: break-word;
                    overflow-wrap: break-word
                    }
                    
                }

                .file__area{
                    // border:2px solid yellow;
                    width: 90%;
                    // max-height: 200px;
                    // height: 120px;
                    border-radius: 10px;

                   
                }
            }
        }
    
    
        
    }

    .Ans__area {
        // margin-top: -40px;
        width: 95%;
        height: auto;
        margin: 0 auto;
     
        // border:2px solid green;
        // background-image: url('data:image/svg+xml,%3Csvg%20width%3D%22100%25%22%20height%3D%22100%25%22%20viewBox%3D%220%200%20373%20359%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20opacity%3D%220.3%22%20d%3D%22M6.83095%2032.03C7.2004%2014.3463%2021.8461%200.316289%2039.5293%200.706156L297.942%206.40344C313.562%206.74782%20326.65%2018.3214%20328.902%2033.7818L367.526%20298.866C370.211%20317.289%20356.662%20334.105%20338.089%20335.402L34.953%20356.56C16.1828%20357.87%200.338834%20342.781%200.731844%20323.97L6.83095%2032.03Z%22%20fill%3D%22url(%23paint0_radial_1365_386)%22%2F%3E%3Cpath%20d%3D%22M10.97%2045.8939C11.5523%2029.2747%2024.7669%2015.8712%2041.3762%2015.0531L313.426%201.65365C331.695%200.75383%20347%2015.3236%20347%2033.6149V327C347%20344.673%20332.673%20359%20315%20359H33.1407C15.0283%20359%200.526106%20343.981%201.16031%20325.88L10.97%2045.8939Z%22%20fill%3D%22url(%23paint1_radial_1365_386)%22%2F%3E%3Cdefs%3E%3CradialGradient%20id%3D%22paint0_radial_1365_386%22%20cx%3D%220%22%20cy%3D%220%22%20r%3D%221%22%20gradientUnits%3D%22userSpaceOnUse%22%20gradientTransform%3D%22translate(172.466%20209)%20rotate(54.8074)%20scale(274.285%20196.144)%22%3E%3Cstop%20stop-color%3D%22%23664A86%22%2F%3E%3Cstop%20offset%3D%220.664497%22%20stop-color%3D%22%23433566%22%2F%3E%3Cstop%20offset%3D%221%22%20stop-color%3D%22%23302955%22%2F%3E%3C%2FradialGradient%3E%3CradialGradient%20id%3D%22paint1_radial_1365_386%22%20cx%3D%220%22%20cy%3D%220%22%20r%3D%221%22%20gradientUnits%3D%22userSpaceOnUse%22%20gradientTransform%3D%22translate(172.466%20209)%20rotate(54.8074)%20scale(274.285%20196.144)%22%3E%3Cstop%20stop-color%3D%22%23664A86%22%2F%3E%3Cstop%20offset%3D%220.664497%22%20stop-color%3D%22%23433566%22%2F%3E%3Cstop%20offset%3D%221%22%20stop-color%3D%22%23302955%22%2F%3E%3C%2FradialGradient%3E%3C%2Fdefs%3E%3C%2Fsvg%3E');
        // background-repeat: no-repeat;

        // background-color: radial-gradient(62.96% 71.37% at 50% 56%, #664A86 0%, #433566 66.45%, #302955 100%);;
        background: radial-gradient(circle,
                        hsla(268, 29%, 41%, 1) 0%,
                        hsla(257, 32%, 30%, 1) 50%,
                        hsla(250, 35%, 25%, 1) 100%);

                        border-top-left-radius:10%;
                        border-top-right-radius:10%;

  
        @media (min-width:425px){
            width: 85%;
        }
        

        .shadow__area {
           
            width: 100%;
            height: auto;
            
            // clip-path: polygon(0 0, 94% 5%, 100% 100%, 0% 100%);
            
            // background-color: rgba(0, 0, 0, 0.12);
            // border: 2px solid red;
            border-radius: 5%;
            // position: relative;
            // top:-100px;
          

            .main__area {

                // clip-path: polygon(36% 0, 95% 0, 100% 0, 100% 100%, 81% 100%, 50% 100%, 19% 100%, 0 100%, 0 0, 17% 0);  
                //               background: radial-gradient(circle,
                //         hsla(268, 29%, 41%, 1) 0%,
                //         hsla(257, 32%, 30%, 1) 50%,
                //         hsla(250, 35%, 25%, 1) 100%);
                // border-radius: 20%;
                width: 100%;
            height: 100%;
            padding:5% 8%;
            // position: relative;
            // top:-100px;
            }
        }
    }

}

@media (min-width:821px){
    body{
        width: 30%;
    }
}


.wrong,.right{

    position: absolute;
   
    // isolation: isolate;
    // z-index: 999;
    // border:2px solid green;
}

