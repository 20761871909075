
    
    .video_container{
        position: relative; 
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: cover;
        min-width:5% ;
       
    }
    #vect{ 
        
        position: absolute;
         left: 8rem; 
         
    }
    #vect {
        z-index: 1;
        
        
    }
    
    
    
      .back_ground_img{
        overflow: hidden;
        
      }
      
      @media screen and (105px <= width < 453px){
        #vect img { 
             height:50% ;
             width: 50%;   
             padding: auto; 
        }
    }
    
    @media screen and ( width =280px){
      #vect img { 
           height:50% ;
           width: 50%;   
           padding: auto; 
           transform: translate(-80%,-1%);
      }
    }
    