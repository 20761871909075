.container {
    width: 100%; /* Adjust width as needed */
    max-width: 500px;
    padding-top: 40px;
    box-sizing: border-box;
    min-height: 100vh; /* Adjust height as needed */
    position: relative; 
    /* Ensure proper positioning for overlay */
    // background-color: #FFC05C;
    // overflow: hidden;
    // border: 12px solid red;
    

    @media (min-width:821px){
        width: 100%;
        // border: 12px solid red;
    }
  }
  
  .container::before {
    content: ""; /* Create a pseudo-element */
    position: absolute; /* Position the pseudo-element */
    top: 0;
    left: 0;
    width: 100%; /* Cover the entire container */
    min-height:100%;
    background-image: url('../../../../assets/theme6/bgart.png'); /* Specify the URL of your transparent image */
    background-color: #FFC05C; /* Specify the color overlay (here, it's red with 50% opacity) */
    opacity: 1; /* Adjust the opacity of the image */
    // z-index: -1;

    background-size: 100%;
    @media (min-width:821px){
        width: 100%;
        // border: 12px solid red;
    }
  }


  .logo{
    position: relative;
    // top:3vh;
    // left:3vh;
    // width: inherit;
    // border:2px solid green;
    display: flex;
    z-index: 999;
    justify-content: space-between;
  }